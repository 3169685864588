import React, { useState } from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { applyButton, palette } from "../../styles/styles";
import { Mail } from "@styled-icons/fluentui-system-filled";
import { Earth } from "@styled-icons/ionicons-solid/Earth";
import {
  FacebookWithCircle,
  InstagramWithCircle,
  LinkedinWithCircle,
} from "@styled-icons/entypo-social";
import { Asterisk } from "@styled-icons/fa-solid";
import LogoZ from "../../images/logoZ.png";
import { sendEmail, countryToFlag, countries } from "../../utils/compsUtils";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
const ContactSection = (props) => {
  const { className } = props;
  const onSubmit = (e) => {
    e.preventDefault();
    handleToggle();
    const payload = {
      email: "erick@lapieza.io",
      // email: "alejandro.bautista@lapieza.io",
      data: {
        name: e.target.elements.name.value,
        email: e.target.elements.email.value,
        phone: e.target.elements.phone.value,
        phoneCode: e.target.elements.phoneCode.value,
        body: e.target.elements.body.value,
      },
      templateId: "ad523b9c31c742d4ae248f1806805abd",
      cc: "pol@lapieza.io",
      // cc: "alejandro.bautista@lapieza.io",
    };

    sendEmail(payload)
      .then((response) => {
        handleClose();
        NotificationManager.success("Mensaje enviado", null, 3000);
      })
      .catch(() => {
        handleClose();
        NotificationManager.error(
          "No fue posible enviar el mensaje, por favor intenta de nuevo",
          null,
          3000
        );
      });
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <section className={className}>
      <div className="left">
        <h1>Contacta a LaPieza.io</h1>
        <p>
          ¿Cómo podemos ayudarte? Contáctanos por correo electrónico o rellena
          el formulario:
        </p>
        <div className="infoItem">
          <Earth className="icon" />
          <a href="https://lapieza.io/" target="_blank" rel="noreferrer">
            https://lapieza.io//
          </a>
        </div>
        <div className="infoItem">
          <Mail className="icon" />
          <a href="mailto:erick@lapieza.io" target="_blank" rel="noreferrer">
            erick@lapieza.io
          </a>
        </div>
        <div className="infoItem" id="pol@lapieza.io">
          <Mail className="icon" />
          <a href="mailto:pol@lapieza.io" target="_blank" rel="noreferrer">
            pol@lapieza.io
          </a>
        </div>
        <div className="social">
          <a
            href="https://www.facebook.com/lapieza.io/"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookWithCircle className="socialIcon" />
          </a>
          <a
            href="https://www.instagram.com/lapieza.io/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramWithCircle className="socialIcon" />
          </a>
          <a
            href="https://www.linkedin.com/company/lapieza"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinWithCircle className="socialIcon" />
          </a>
        </div>
        <figure className="logoZ">
          <img alt="lapieza.io" src={LogoZ} />
        </figure>
      </div>

      <div className="right">
        <form className="form" onSubmit={onSubmit}>
          <div className="field">
            <label htmlFor="name">
              Nombre completo
              <Asterisk className="asterisk" />
            </label>
            <input required type="text" name="name" />
          </div>
          <div className="field">
            <label htmlFor="email">
              Correo electrónico
              <Asterisk className="asterisk" />
            </label>
            <input required type="email" name="email" />
          </div>
          <div className="field">
            <label htmlFor="phone">
              Número de teléfono
              <Asterisk className="asterisk" />
            </label>
            <div className="phone">
              <select className="form-control" name="phoneCode">
                {countries.map((item) => (
                  <option key={item.name} value={item.phoneCode}>
                    {countryToFlag(item?.iso2 || "")}{" "}
                    {`  +${item.phoneCode || ""}`}
                  </option>
                ))}
              </select>
              <input required type="text" name="phone" />
            </div>
          </div>
          <div className="field">
            <label htmlFor="body">
              Mensaje
              <Asterisk className="asterisk" />
            </label>
            <textarea required rows={8} cols={50} name="body" />
          </div>
          <button type="submit">Contáctanos</button>
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </section>
  );
};

export default styled(ContactSection)`
  padding: 0 8%;
  margin: 1rem 0;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .left {
    padding: 4rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 2rem;
    @media (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
    h1 {
      font-size: 4.6rem;
      color: ${palette.fontBlue};
      font-family: SofiaProSemiBoldAz, sans-serif;
    }
    p {
      font-size: 2.4rem;
      margin-bottom: 4rem;
    }
    .infoItem {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      grid-gap: 2rem;
      .icon {
        width: 3rem;
        color: ${palette.fontRed};
      }
      a {
        text-decoration: none;
        font-size: 2rem;
        color: inherit;
      }
    }
    .social {
      display: flex;
      flex-direction: row;
      margin: 3rem 0;
      grid-gap: 2rem;
      .socialIcon {
        width: 4rem;
        color: ${palette.fontRed};
      }
    }
    .logoZ {
      margin-top: 3rem;
    }
  }
  .right {
    padding: 4rem 0;
    @media (max-width: 768px) {
      padding: 4rem 0;
    }
    .form {
      width: 90%;
      margin: auto;
      background-color: ${palette.whiteWhite};
      border-radius: 8px;
      box-shadow: ${palette.regBS};
      padding: 4rem;
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      .field {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        label {
          font-size: 1.6rem;
          margin-bottom: 0.6rem;
        }
        .asterisk {
          width: 0.9rem;
          color: ${palette.fontRed};
          margin-left: 1rem;
          margin-top: -0.75rem;
        }
        input {
          border: solid ${palette.borderInput} 1px;
          border-radius: 4px;
          height: 4rem;
          font-size: 1.6rem;
        }
        textarea {
          width: 100%;
          resize: none;
          height: 16rem;
          font-size: 1.6rem;
          border-radius: 4px;
          border: solid ${palette.borderInput} 1px;
        }
      }
      .phone {
        display: flex;
        flex-direction: row;
        grid-gap: 2rem;
        width: 100%;
        input {
        }
        select {
          font-size: 1.6rem;
          border-radius: 4px;
          border: solid ${palette.borderInput} 1px;
          padding: 0 1rem;
        }
      }
      button {
        ${applyButton};
        font-size: 1.6rem;
        margin: 0 auto;
      }
    }
  }
`;
