import React from "react";
import styled from "styled-components";
import Seo from "../comps/Seo";
import Navbar from "../comps/Navbar";
import Footer from "../comps/Footer";
import { palette } from "../styles/styles";
import ContactSection from "../comps/contact/ContactSection";
import DrawerMenu from "../comps/DrawerMenu";
import circle from "../images/sections/circle.png";
import {NotificationContainer} from "react-notifications";

const info = (props) => {
  const { className } = props;

  return (
    <>
      <Seo />
      <div className={className}>
      <NotificationContainer/>
        <Navbar />
        <DrawerMenu />
        <ContactSection />
        <Footer />
      </div>
    </>
  );
};

export default styled(info)`
  background-color: ${palette.bgWhite};
  max-width: 100%;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 2rem;
  background-image: url(${circle}), url(${circle});
  background-attachment: local;
  background-repeat: no-repeat;
  .notification {
    font-size: 1.5rem;
  }
  @media (min-width: 1441px) {
    background-position: -15rem 70rem, 114rem 70rem;
    background-size: 40rem, 40rem;
  }
  @media (max-width: 1440px) {
    background-position: -25rem 80rem, 124rem 80rem;
    background-size: 40rem, 40rem;
  }
  @media (max-width: 1024px) {
    background-position: 98rem 80rem, -22rem 85rem;
    background-size: 40rem, 40rem;
  }
  @media (max-width: 768px) {
    background-position: 70rem 135em, -22rem 44rem;
    background-size: 40rem, 40rem;
    grid-row-gap: 4rem;
  }
`;
